type Translation = {
  [index: string]: {
    [index: string]: string;
  };
};

export const translation: Translation = {
  accelerometer: { FR: 'Acceléromètre', EN: 'Accelerometer' },
  checkDatasource: { FR: 'Vérifiez la connexion à la datasource', EN: 'Please check datasource connection.' },
  differentSettings: {
    FR: 'Les paramètres reçus par la balise sont différents de ceux envoyés par le client.',
    EN: 'Settings receieved by beacon are different from those sent by client.',
  },
  env: { FR: 'Enveloppe', EN: 'Envelope' },
  errorDatasource: { FR: 'Erreur datasource', EN: 'Datasource error' },
  errorRequest: { FR: 'Erreur lors de la requête des paramètres', EN: 'Cannot request beacon settings.' },
  errorSettings: { FR: 'Erreur paramètres', EN: 'Beacon settings error' },
  microphone: { FR: 'Microphone', EN: 'Microphone' },
  settings: { FR: 'Paramètres', EN: 'Settings' },
  velocity: { FR: 'Velocité', EN: 'Velocity' },
  noBeaconSettings: { FR: 'Paramètres de la balise introuvables', EN: 'Beacon settings not found' },
  noBeaconSettingsReceived: {
    FR: "Paramètres de la balise non-reçus. Attendez la réception des paramètres ou renvoyez-les. Date d'envoi:",
    EN: 'Beacon settings not received. Wait for settings reception or send them again. Sending date:',
  },
  missingTemplate: { FR: "Variable 'beacon_selection' introuvable", EN: "Cannot find 'beacon_selection' template" },
  noBeaconTemplate: { FR: 'Variable de la balise introuvable', EN: 'Beacon template not found' },
  noSettingsBeacon: { FR: 'Aucun paramètre renvoyé par la balise.', EN: 'Cannot find settings sent by beacon. ' },
  noSettingsClient: { FR: 'Aucun paramètre renvoyé par le client.', EN: 'Cannot find settings sent by client.' },
  requestReboot: { FR: 'Procédez à un reboot de la balise.', EN: 'Please request a reboot.' },
  peak: { FR: 'Pic', EN: 'Peak' },
  ultrasound: { FR: 'Ultrasons', EN: 'Ultrasound' },
  spectrogram: { FR: 'Spectrogramme', EN: 'Spectrogram' },
  reception: { FR: 'Réception', EN: 'Received' },
  creation: { FR: 'Création', EN: 'Created' },
  sensor: { FR: 'Capteur', EN: 'Sensor' },
  spectrum: { FR: 'Spectre', EN: 'Spectrum' },
  cutOff: { FR: 'Fréq. de coupure', EN: 'Cutoff frequency' },
};
