import React from 'react';

import { SpectrogramSettings } from './types';
import { getDico } from './useTranslation';

interface SpectrumSettingsLegendProps {
  spectrumSettings: SpectrogramSettings;
}
// to be rendered only if beaconVersion > '4.44' && !ctrl.spectrumSettings.isDefault"
export const SpectrumSettingsLegend: React.FunctionComponent<SpectrumSettingsLegendProps> = ({ spectrumSettings }) => {
  const dico = getDico();
  return (
    <>
      <div style={{ padding: '0 8px' }}>
        <div
          style={{
            marginTop: '5px',
            marginBottom: '2px',
            paddingTop: '8px',
            borderTop: '1px solid #d7d7d7',
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <div style={{ fontSize: '12px', fontWeight: 500 }}>{dico.settings?.toUpperCase()}</div>
          <div style={{ fontSize: '11px', marginLeft: '8px' }}>
            Created: {spectrumSettings?.dateCreation} | Received: {spectrumSettings?.dateReception}
          </div>
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap', fontSize: '11px' }}>
          <div style={{ marginRight: '8px' }}>
            <span style={{ fontWeight: 500 }}>{dico?.sensor?.toUpperCase()}</span>: {spectrumSettings.sensorType} |
          </div>
          <div style={{ marginRight: '8px' }}>
            <span style={{ fontWeight: 500 }}>{dico.spectrum?.toUpperCase()}</span>: {spectrumSettings.spectrumType} |
          </div>
          <div style={{ marginRight: '8px' }}>
            <span style={{ fontWeight: 500 }}>ORIENTATION</span>: {spectrumSettings?.orientation} |
          </div>
          {spectrumSettings.spectrumType === dico.spectrumType.envPeak ||
            (spectrumSettings.spectrumType === dico.spectrumType.envRms && (
              <div>
                <span style={{ fontWeight: 500 }}>{dico.cutOff?.toUpperCase()}</span>: {spectrumSettings.cutoffValue}Hz
              </div>
            ))}
        </div>
      </div>
    </>
  );
};
