import { getTemplateSrv } from '@grafana/runtime';

import { translation } from './translations';

export interface DicoProps {
  checkDatasource: string;
  differentSettings: string;
  env: string;
  errorDatasource: string;
  errorRequest: string;
  errorSettings: string;
  missingTemplate: string;
  noBeaconSettings: string;
  noBeaconTemplate: string;
  noSettingsBeacon: string;
  noSettingsClient: string;
  noBeaconSettingsReceived: string;
  requestReboot: string;
  settings: string;
  velocity: string;
  spectrumType: {
    rms: string;
    peak: string;
    velRms: string;
    velPeak: string;
    envRms: string;
    envPeak: string;
  };
  sensorType: {
    accelerometer: string;
    microphone: string;
  };
  ultrasound: string;
  spectrogram: string;
  reception: string;
  creation: string;
  sensor: string;
  spectrum: string;
  cutOff: string;
}

export const getDico = () => {
  let lang = getTemplateSrv().replace('$lang_selection');
  let keyLang: string;

  if (!lang) {
    keyLang = 'EN';
  }

  keyLang = lang.toUpperCase();

  const dico: DicoProps = {
    checkDatasource: translation.checkDatasource[`${keyLang}`],
    differentSettings: translation.differentSettings[`${keyLang}`],
    env: translation.env[`${keyLang}`],
    errorDatasource: translation.errorDatasource[`${keyLang}`],
    errorRequest: translation.errorRequest[`${keyLang}`],
    errorSettings: translation.errorSettings[`${keyLang}`],
    missingTemplate: translation.missingTemplate[`${keyLang}`],
    noBeaconSettings: translation.noBeaconSettings[`${keyLang}`],
    noBeaconTemplate: translation.noBeaconTemplate[`${keyLang}`],
    noSettingsBeacon: translation.noSettingsBeacon[`${keyLang}`],
    noSettingsClient: translation.noSettingsClient[`${keyLang}`],
    noBeaconSettingsReceived: translation.noBeaconSettingsReceived[`${keyLang}`],
    requestReboot: translation.requestReboot[`${keyLang}`],
    settings: translation.settings[`${keyLang}`],
    velocity: translation.velocity[`${keyLang}`],
    spectrumType: {
      rms: 'RMS',
      peak: translation.peak[`${keyLang}`],
      velRms: `${translation.velocity[`${keyLang}`]} RMS`,
      velPeak: `${translation.velocity[`${keyLang}`]} ${translation.peak[`${keyLang}`]}`,
      envRms: `${translation.env[`${keyLang}`]} RMS`,
      envPeak: `${translation.env[`${keyLang}`]} ${translation.peak[`${keyLang}`]}`,
    },
    sensorType: {
      accelerometer: translation.accelerometer[`${keyLang}`],
      microphone: translation.microphone[`${keyLang}`],
    },
    ultrasound: translation.ultrasound[`${keyLang}`],
    spectrogram: translation.spectrogram[`${keyLang}`],
    reception: translation.reception[`${keyLang}`],
    creation: translation.creation[`${keyLang}`],
    sensor: translation.sensor[`${keyLang}`],
    spectrum: translation.spectrum[`${keyLang}`],
    cutOff: translation.cutOff[`${keyLang}`],
  };

  return dico;
};
