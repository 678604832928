import { DataFrame, FieldType } from '@grafana/data';

/**
 * These functions are used in the custom spectrogram panel to displayed
 * the reception date of the beacon settings as a marker (the same as annotations)
 */

// Single annotation
export const getNewAnnotation = (
  time: number,
  text: string,
  user: { id: number; login: string; email: string },
  panelId: number,
  dashboardId: number,
  dashboardUid: string
): DataFrame[] => {
  return [
    {
      fields: [
        {
          name: 'type',
          config: {},
          values: ['Annotations & Alerts'],
          type: 'string' as FieldType,
          state: null,
        },
        {
          name: 'color',
          config: {},
          values: ['rgb(255, 115, 131)'],
          type: 'string' as FieldType,
          state: null,
        },
        {
          name: 'time',
          config: {},
          values: [time],
          type: 'time' as FieldType,
          state: null,
        },
        {
          name: 'timeEnd',
          config: {},
          values: [time],
          type: 'number' as FieldType,
          state: null,
        },
        {
          name: 'text',
          config: {},
          values: [text],
          type: 'string' as FieldType,
          state: null,
        },
        {
          name: 'tags',
          config: {},
          values: [[]],
          type: 'other' as FieldType,
          state: null,
        },
        {
          name: 'id',
          config: {},
          values: [null],
          type: 'number' as FieldType,
          state: null,
        },
        {
          name: 'userId',
          config: {},
          values: [user.id],
          type: 'number' as FieldType,
          state: null,
        },
        {
          name: 'login',
          config: {},
          values: [user?.login],
          type: 'string' as FieldType,
          state: null,
        },
        {
          name: 'email',
          config: {},
          values: [user?.email],
          type: 'string' as FieldType,
          state: null,
        },
        {
          name: 'prevState',
          config: {},
          values: [''],
          type: 'string' as FieldType,
          state: null,
        },
        {
          name: 'newState',
          config: {},
          values: [''],
          type: 'string' as FieldType,
          state: null,
        },
        {
          name: 'data',
          config: {},
          values: [{}],
          type: 'other' as FieldType,
          state: null,
        },
        {
          name: 'panelId',
          config: {},
          values: [panelId],
          type: 'number' as FieldType,
          state: null,
        },
        {
          name: 'alertId',
          config: {},
          values: [0],
          type: 'number' as FieldType,
          state: null,
        },
        {
          name: 'dashboardId',
          config: {},
          values: [dashboardId],
          type: 'number' as FieldType,
          state: null,
        },
        {
          name: 'dashboardUID',
          config: {},
          values: [dashboardUid],
          type: 'string' as FieldType,
          state: null,
        },
        {
          name: 'source',
          config: {},
          values: [
            {
              builtIn: 1,
              datasource: '-- Grafana --',
              enable: true,
              hide: true,
              iconColor: 'rgba(0, 211, 255, 1)',
              limit: 100,
              name: 'Annotations & Alerts',
              showIn: 0,
              tags: [],
              type: 'dashboard',
            },
          ],
          type: 'other' as FieldType,
          state: null,
        },
        {
          name: 'isRegion',
          config: {},
          values: [false],
          type: 'boolean' as FieldType,
          state: null,
        },
      ],
      length: 1,
    },
  ];
};

export interface AnnotProps {
  color: string;
  dashboardId: number;
  dashboardUID: string;
  id: null;
  isRegion: boolean;
  panelId: number;
  tags: string[];
  text: string;
  time: number;
  timeEnd: number;
  type: string;
  user: { id: number; login: string; email: string };
}

// Add settings annotation to the existing annotations
export const addAnnotation = (annotations: DataFrame[], annot: AnnotProps) => {
  const newDataAnnot = [...annotations];
  newDataAnnot[0]!.fields.map((field) => {
    switch (field.name) {
      case 'type':
        field.values.push('Annotations & Alerts');
        return field;
      case 'color':
        field.values.push('rgb(255, 115, 131)');
        return field;
      case 'time':
        field.values.push(annot.time);
        return field;
      case 'timeEnd':
        field.values.push(annot.time);
        return field;
      case 'text':
        field.values.push(annot.text);
        return field;
      case 'tags':
        field.values.push([]);
        return field;
      case 'id':
        field.values.push(null);
        return field;
      case 'userId':
        field.values.push(annot.user.id);
        return field;
      case 'login':
        field.values.push(annot.user.login);
        return field;
      case 'email':
        field.values.push(annot.user.email);
        return field;
      case 'prevState':
        field.values.push('');
        return field;
      case 'newState':
        field.values.push('');
        return field;
      case 'data':
        field.values.push({});
        return field;
      case 'panelId':
        field.values.push(annot.panelId);
        return field;
      case 'alertId':
        field.values.push(0);
        return field;
      case 'dashboardId':
        field.values.push(annot.dashboardId);
        return field;
      case 'dashboardUID':
        field.values.push(annot.dashboardUID);
        return field;
      case 'source':
        field.values.push({
          builtIn: 1,
          datasource: '-- Grafana --',
          enable: true,
          hide: true,
          iconColor: 'rgba(0, 211, 255, 1)',
          limit: 100,
          name: 'Annotations & Alerts',
          showIn: 0,
          tags: [],
          type: 'dashboard',
        });
        return field;
      case 'isRegion':
        field.values.push(false);
        return field;
      default:
        return field;
    }
  });

  return newDataAnnot;
};

// Retrieve useful elements to build the setting annotation
export const getAnnot = (
  time: number,
  spectrumSettings: { sensorType: string; spectrumType: string },
  dashboardId: number,
  dashboardUid: string,
  panelId: number,
  user: { id: number; login: string; email: string }
) => {
  return {
    type: 'Annotations & Alerts',
    color: 'rgb(255, 115, 131)',
    time: time,
    timeEnd: time,
    text: `Settings received : </br> ${spectrumSettings.sensorType} | ${spectrumSettings.spectrumType}`,
    tags: [],
    id: null,
    dashboardId: dashboardId,
    dashboardUID: dashboardUid,
    panelId: panelId,
    isRegion: false,
    user: user,
  };
};
